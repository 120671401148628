$(document).ready(function(){
        
    if($('.view').length > 0){
    
        $(window).on('resize', function(){
        });

        $(window).on('scroll', function(){
        });

        $(window).on('scrollstart', function(){
        });

        $(window).on('scrollstop', function(){
        });

        $(window).trigger('resize');

        $(window).trigger('scroll');
        
        $(document).on('click', '.popup-open', function(e){
            e.preventDefault();
            
            $('form').show();
        });
        
        $(document).on('click', '.popup-close', function(e){
            e.preventDefault();
            
            $('form').hide();
        });
        
        $(document).on('click', '.form-send', function(e){
            e.preventDefault();
            
            $('form').submit();
        });
    }
});