var bLazy, clickEvent, maxWidth = 1024, mobileWidth = 769, isMobile = false, isTablet = false, isIE = false, windowW = 0, windowH = 0;

$(window).load(function(){
    $(window).trigger('resize');
});

$(document).ready(function(){
    
});

$(window).on('resize', function(){
    windowW = $(window).width();
    windowH = $(window).height();
});