$(document).ready(function(){
        
    if($('.list').length > 0){
    
        $(window).on('resize', function(){
        });

        $(window).on('scroll', function(){
        });

        $(window).on('scrollstart', function(){
        });

        $(window).on('scrollstop', function(){
        });

        $(window).trigger('resize');

        $(window).trigger('scroll');
    }
});